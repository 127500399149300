<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/element">element</router-link> |
    <router-link to="/login">login</router-link> |
    <router-link to="/index">index</router-link> |
    <router-link to="/search">search</router-link> |
  </nav> -->
  <router-view />
</template>
<script>
export default {  
  mounted () {  
    const script = document.createElement('script')  
    script.src = 'https://api.tongjiniao.com/c?_=676102709358800896'  
    script.language = 'JavaScript'  
    document.body.appendChild(script)  
  },  
  watch: {  
    '$route' () {  
      if (window._czc) {  
        let location = window.location  
        let contentUrl = location.pathname + location.hash  
        let refererUrl = '/'  
        window._czc.push(['_trackPageview', contentUrl, refererUrl])  
      }  
    }  
  }  
} 
</script>
<style lang="scss">
body {
  margin: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.main {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}


nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
