// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'

// createApp(App).use(router).mount('#app')
import { createApp } from 'vue'
import { createPinia } from 'pinia'//引入pinia
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"; //pinia 持久化

import App from './App.vue'
import router from './router'
// import store from './store'
import Axios from 'axios'
import 'element-plus/theme-chalk/index.css'
import ElementPlus from 'element-plus'
import "@element-plus/icons-vue"

import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate); //pinia数据持久化

createApp(App).use(ElementPlus,{locale: zhCn}).use(pinia).use(router).mount('#app')

