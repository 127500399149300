import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore, isLoginWinShow } from "../store/userLogin";

const routes = [
  {
    path: '/',
    name: 'home',
    component: function () {
      return import('../views/Index.vue')
    },
    meta: { title: '易寻标·首页' }
  }, {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/LoginView.vue')
    }
  }, {
    path: '/index',
    name: 'index',
    component: function () {
      return import('../views/Index.vue')
    }
  }, {
    path: '/BulletinList',
    name: 'BulletinList',
    component: function () {
      return import('../views/BulletinList.vue')
    },
    meta: { title: '招标采购信息' }
  }, {
    path: '/BulletinDetail',
    name: 'BulletinDetail',
    component: function () {
      return import('../views/BulletinDetail.vue')
    }
  }, {
    path: '/ProjectList',
    name: 'ProjectList',
    component: function () {
      return import('../views/ProjectList.vue')
    },
    meta: { title: '拟建项目信息' }
  }, {
    path: '/ProjectDetail',
    name: 'ProjectDetail',
    component: function () {
      return import('../views/ProjectDetail.vue')
    }
  }, {
    path: '/MemberProjectLibrary',
    name: 'MemberProjectLibrary',
    component: function () {
      return import('../views/member/MemberProjectLibrary.vue')
    },
    meta: { title: '我的项目库' }
  }, {
    path: '/MemberAssignRecord',
    name: 'MemberAssignRecord',
    component: function () {
      return import('../views/member/MemberAssignRecord.vue')
    },
    meta: { title: '我的指派记录' }
  }, {
    path: '/MemberBrowsingHistory',
    name: 'MemberBrowsingHistory',
    component: function () {
      return import('../views/member/MemberBrowsingHistory.vue')
    },
    meta: { title: '项目浏览记录' }
  }, {
    path: '/MemberDownloadRecords',
    name: 'MemberDownloadRecords',
    component: function () {
      return import('../views/member/MemberDownloadRecords.vue')
    },
    meta: { title: '项目下载记录' }
  }, {
    path: '/MemberCollectionRecord',
    name: 'MemberCollectionRecord',
    component: function () {
      return import('../views/member/MemberCollectionRecord.vue')
    },
    meta: { title: '项目收藏记录' }
  }, {
    path: '/MemberBulletin',
    name: 'MemberBulletin',
    component: function () {
      return import('../views/member/MemberBulletin.vue')
    },
    meta: { title: '招标资讯记录' }
  }, {
    path: '/MemberOrderRecords',
    name: 'MemberOrderRecords',
    component: function () {
      return import('../views/member/MemberOrderRecords.vue')
    },
    meta: { title: '我的订单记录' }
  }, {
    path: '/MemberInformation',
    name: 'MemberInformation',
    component: function () {
      return import('../views/member/MemberInformation.vue')
    },
    meta: { title: '个人信息' }
  }, {
    path: '/MemberEnterpriseSubAccount',
    name: 'MemberEnterpriseSubAccount',
    component: function () {
      return import('../views/member/MemberEnterpriseSubAccount.vue')
    },
    meta: { title: '企业团队子账户' }
  },
  {
    /* 统一错误页面*/
    path: "/:catchAll(.*)",
    component: () => import("../views/ErrorPage.vue"),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/* 设置页面标题*/
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  var urlObj = ['/MemberCollectionRecord', '/MemberBrowsingHistory', '/MemberCollectionRecord',
    '/MemberBulletin', '/MemberOrderRecords', '/MemberInformation', '/MemberEnterpriseSubAccount'
  ];
  /* 路由守卫检查当前登录信息是否需要进行验证登录*/
  const userStore = useUserStore();
  if (urlObj.includes(to.fullPath)) {
    if (userStore.userInfo.phone == '') {
      const isLoginWin = isLoginWinShow();
      isLoginWin.show_login = true;
      next('/');
    }
  }
  next();
});
export default router
