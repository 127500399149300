import { defineStore } from "pinia";//引入pinia

export const useUserStore = defineStore('userInfo', {
    id: 'userId',
    state: () => {
        return {
            userInfo: {
                phone: '',
                fullname: '',
                date: '',
            }
        }
    },

    actions: {
        setUserLogin(userInfo) {
            this.userInfo = userInfo
        }
    },
    // 开启数据缓存
    persist: {
        enabled: true
    }
})

export const useSessionId = defineStore('session_id',{
    // const sessionid = ref('')
    state: () => {
        return {
            session_id: ""
        }
    },

    actions: {
        setSessionId(sessionid) {
            this.session_id = sessionid
        }
    },
    // 开启数据缓存
    persist: {
        enabled: true
    }
})
/* 控制展示登录窗口*/
export const isLoginWinShow = defineStore('show_login',{
    // const sessionid = ref('')
    id: 'showLogin',
    state: () => {
        return {
            show_login: false
        }
    },
    actions: {
        setShowLogin(show_login) {
            this.show_login = show_login
        }
    },
    // 开启数据缓存
    persist: {
        enabled: true
    }
})

